
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";
@Options({
    name: "flowable-nodes-approval-node-config",
})
export default class ApprovalNodeConfig extends NodesConfigMixins {
    private showOrgSelect = false;
    private orgPickerType = "org";
    private approvalTypes: any = [
        { name: "指定人员", type: "ASSIGN_USER" },
        { name: "发起人自选", type: "SELF_SELECT" },
        { name: "发起人自己", type: "SELF" },
        { name: "组织", type: "ASSIGN_ORG" },
        { name: "主管", type: "LEADER" },
        { name: "连续多级主管", type: "LEADER_TOP" },
        { name: "部门", type: "ASSIGN_DEPT" },
        { name: "区域负责人", type: "AREA_LEADER" },
        { name: "区域负责人(设备)", type: "AREA_PROJECT_LEADER" },
        { name: "区域处理人(设备)", type: "AREA_PROJECT_HANDLE" },
        { name: "区域负责人(组织)", type: "AREA_ORGCODE_LEADER" },
        { name: "区域处理人(组织)", type: "AREA_ORGCODE_HANDLE" },
        { name: "表单内联系人", type: "FORM_USER" },
    ];
    get forms() {
        return this.setup.formItems.filter((f: any) => {
            return f.name === "UserPicker";
        });
    }
    get deviceForms() {
        return this.setup.formItems.filter((f: any) => {
            return f.props.required;
        });
    }
    get nodeOptions() {
        let values: any = [];
        const excType = ["ROOT", "EMPTY", "CONDITION", "CONDITIONS", "CONCURRENT", "CONCURRENTS"];
        this.nodeMap.forEach((v: any) => {
            if (excType.indexOf(v.type) === -1) {
                values.push({ id: v.id, name: v.name });
            }
        });
        return values;
    }
    get showMode() {
        switch (this.nodeProps.assignedType) {
            case "ASSIGN_USER":
                return this.nodeProps.assignedUser.length > 0;
            case "ASSIGN_ORG":
                return true;
            case "ASSIGN_DEPT":
                return true;
            case "LEADER_TOP":
                return true;
            case "AREA_PROJECT_LEADER":
                return true;
            case "AREA_PROJECT_HANDLE":
                return true;
            case "AREA_ORGCODE_LEADER":
                return true;
            case "AREA_ORGCODE_HANDLE":
                return true;
            case "SELF_SELECT":
                return this.nodeProps.selfSelect.multiple;
            default:
                return false;
        }
    }
    get assignedNoUser() {
        if (!this.config.nobody.assignedUser) this.config.nobody.assignedUser = [];
        return this.config.nobody.assignedUser || [];
    }
    set assignedNoUser(val: any) {
        this.config.nobody.assignedUser = val;
    }
    public noUserItemRemove(index: any) {
        this.assignedNoUser.splice(index, 1);
    }
    public noUserSelected(select: any) {
        this.assignedNoUser.length = 0;
        select.forEach((val: any) => this.assignedNoUser.push(val));
    }
    private noUserMultiple = false;
    public noUserPickerOpen(multiple: boolean) {
        this.noUserMultiple = multiple;
        (this.$refs.noUserPicker as any).modalOpen();
    }
}
